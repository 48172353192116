import Link from 'components/common/Link';
import ResponsiveImage from 'components/common/ResponsiveImage';
import Image from 'components/common/Image';

import { MobileFirstBannerProps } from 'interfaces/cms/content';

function Banner(props: { readonly image: any; priority?: boolean }) {
  const { image } = props;
  return (
    <>
      <div className="block md:hidden">
        <ResponsiveImage
          src={image.desktopImage}
          srcMobile={image.mobileImage}
          artDirection={image.artDirection}
          width={image.desktopImage.width}
          height={image.desktopImage.height}
          priority={props.priority}
        />
      </div>
      <div className="hidden md:block overflow-hidden">
        {
          <Image
            src={image.desktopImage.url}
            alt={image.desktopImage.alt || image.desktopImage.name}
            sizes="100vw"
            width={image.desktopImage.width}
            height={image.desktopImage.height}
            priority={props.priority}
          />
        }
      </div>
    </>
  );
}

function MobileFirstBanner({
  url,
  pageSlug,
  image,
  linkRel,
  backgroundColor,
  priority = false,
}: Readonly<MobileFirstBannerProps>) {
  const pageUrl = (pageSlug && `/${pageSlug}`) || url;

  return (
    <div style={{ backgroundColor }} className="flex justify-center">
      <div style={{ width: image?.desktopImage?.width }}>
        {pageUrl ? (
          <Link href={pageUrl} rel={linkRel || ''} isExternalLink={!pageSlug}>
            <Banner image={image} />
          </Link>
        ) : (
          <Banner image={image} priority={priority} />
        )}
      </div>
    </div>
  );
}

export default MobileFirstBanner;
